
 const Images = [
    {
    src: '/webFULL/obarxo-01.jpg',
    thumbnail: '/webTHUMBS/obarxo-thumbs-01.jpg',
    },
    {
    src: '/webFULL/obarxo-02.jpg',
    thumbnail: '/webTHUMBS/obarxo-thumbs-02.jpg',
    },
    {
    src: '/webFULL/obarxo-03.jpg',
    thumbnail: '/webTHUMBS/obarxo-thumbs-03.jpg',
    },
    {
    src: '/webFULL/obarxo-04.jpg',
    thumbnail: '/webTHUMBS/obarxo-thumbs-04.jpg',
    },
    // {
    // src: '/webFULL/obarxo-05.jpg',
    // thumbnail: '/webTHUMBS/obarxo-thumbs-05.jpg',
    // },
    {
    src: '/webFULL/obarxo-06.jpg',
    thumbnail: '/webTHUMBS/obarxo-thumbs-06.jpg',
    },
    {
    src: '/webFULL/obarxo-07.jpg',
    thumbnail: '/webTHUMBS/obarxo-thumbs-07.jpg',
    },
    {
    src: '/webFULL/obarxo-08.jpg',
    thumbnail: '/webTHUMBS/obarxo-thumbs-08.jpg',
    },
    // {
    // src: '/webFULL/obarxo-09.jpg',
    // thumbnail: '/webTHUMBS/obarxo-thumbs-09.jpg',
    // },
    {
    src: '/webFULL/obarxo-10.jpg',
    thumbnail: '/webTHUMBS/obarxo-thumbs-10.jpg',
    },
    {
    src: '/webFULL/obarxo-11.jpg',
    thumbnail: '/webTHUMBS/obarxo-thumbs-11.jpg',
    },
    {
    src: '/webFULL/obarxo-12.jpg',
    thumbnail: '/webTHUMBS/obarxo-thumbs-12.jpg',
    },
    // {
    // src: '/webFULL/obarxo-13.jpg',
    // thumbnail: '/webTHUMBS/obarxo-thumbs-13.jpg',
    // },
    // {
    // src: '/webFULL/obarxo-14.jpg',
    // thumbnail: '/webTHUMBS/obarxo-thumbs-14.jpg',
    // },
    {
    src: '/webFULL/obarxo-15.jpg',
    thumbnail: '/webTHUMBS/obarxo-thumbs-15.jpg',
    },
    {
    src: '/webFULL/obarxo-16.jpg',
    thumbnail: '/webTHUMBS/obarxo-thumbs-16.jpg',
    },
    {
    src: '/webFULL/obarxo-17.jpg',
    thumbnail: '/webTHUMBS/obarxo-thumbs-17.jpg',
    },
    {
    src: '/webFULL/obarxo-18.jpg',
    thumbnail: '/webTHUMBS/obarxo-thumbs-18.jpg',
    },
    {
    src: '/webFULL/obarxo-19.jpg',
    thumbnail: '/webTHUMBS/obarxo-thumbs-19.jpg',
    },
    {
    src: '/webFULL/obarxo-20.jpg',
    thumbnail: '/webTHUMBS/obarxo-thumbs-20.jpg',
    },
    // {
    // src: '/webFULL/obarxo-21.jpg',
    // thumbnail: '/webTHUMBS/obarxo-thumbs-21.jpg',
    // },
    // {
    // src: '/webFULL/obarxo-22.jpg',
    // thumbnail: '/webTHUMBS/obarxo-thumbs-22.jpg',
    // },
    {
    src: '/webFULL/obarxo-23.jpg',
    thumbnail: '/webTHUMBS/obarxo-thumbs-23.jpg',
    },
    {
    src: '/webFULL/obarxo-24.jpg',
    thumbnail: '/webTHUMBS/obarxo-thumbs-24.jpg',
    },
    {
    src: '/webFULL/obarxo-25.jpg',
    thumbnail: '/webTHUMBS/obarxo-thumbs-25.jpg',
    },
    // {
    // src: '/webFULL/obarxo-26.jpg',
    // thumbnail: '/webTHUMBS/obarxo-thumbs-26.jpg',
    // },
    {
    src: '/webFULL/obarxo-27.jpg',
    thumbnail: '/webTHUMBS/obarxo-thumbs-27.jpg',
    },
    {
    src: '/webFULL/obarxo-28.jpg',
    thumbnail: '/webTHUMBS/obarxo-thumbs-28.jpg',
    },
    {
    src: '/webFULL/obarxo-29.jpg',
    thumbnail: '/webTHUMBS/obarxo-thumbs-29.jpg',
    },
    {
    src: '/webFULL/obarxo-30.jpg',
    thumbnail: '/webTHUMBS/obarxo-thumbs-30.jpg',
    },
    {
    src: '/webFULL/obarxo-31.jpg',
    thumbnail: '/webTHUMBS/obarxo-thumbs-31.jpg',
    },
    {
    src: '/webFULL/obarxo-32.jpg',
    thumbnail: '/webTHUMBS/obarxo-thumbs-32.jpg',
    },
    // {
    // src: '/webFULL/obarxo-33.jpg',
    // thumbnail: '/webTHUMBS/obarxo-thumbs-33.jpg',
    // },
    {
    src: '/webFULL/obarxo-34.jpg',
    thumbnail: '/webTHUMBS/obarxo-thumbs-34.jpg',
    },
    // {
    // src: '/webFULL/obarxo-35.jpg',
    // thumbnail: '/webTHUMBS/obarxo-thumbs-35.jpg',
    // },
    {
    src: '/webFULL/obarxo-36.jpg',
    thumbnail: '/webTHUMBS/obarxo-thumbs-36.jpg',
    },
    {
    src: '/webFULL/obarxo-37.jpg',
    thumbnail: '/webTHUMBS/obarxo-thumbs-37.jpg',
    },
    // {
    // src: '/webFULL/obarxo-38.jpg',
    // thumbnail: '/webTHUMBS/obarxo-thumbs-38.jpg',
    // },
    // {
    // src: '/webFULL/obarxo-39.jpg',
    // thumbnail: '/webTHUMBS/obarxo-thumbs-39.jpg',
    // },
    // {
    // src: '/webFULL/obarxo-40.jpg',
    // thumbnail: '/webTHUMBS/obarxo-thumbs-40.jpg',
    // },
    // {
    // src: '/webFULL/obarxo-41.jpg',
    // thumbnail: '/webTHUMBS/obarxo-thumbs-41.jpg',
    // },
    // {
    // src: '/webFULL/obarxo-42.jpg',
    // thumbnail: '/webTHUMBS/obarxo-thumbs-42.jpg',
    // },
    // {
    // src: '/webFULL/obarxo-43.jpg',
    // thumbnail: '/webTHUMBS/obarxo-thumbs-43.jpg',
    // },
    // {
    // src: '/webFULL/obarxo-44.jpg',
    // thumbnail: '/webTHUMBS/obarxo-thumbs-44.jpg',
    // },
    // {
    // src: '/webFULL/obarxo-45.jpg',
    // thumbnail: '/webTHUMBS/obarxo-thumbs-45.jpg',
    // },
    
]

export default Images;